<template>
  <!-- created_by and slug should be generated on backend
  survey_instance_id - make select
  created_by should be only visible on update and disabled? -->
  <div>
    <hero-bar>
      {{ title }}
      <router-link slot="right" to="/reporting" class="button">
        {{ $getTranslation('general.views.reporting') }}
      </router-link>
    </hero-bar>

    <section class="section is-main-section" v-if="item !== null">
      <card-component icon="account">
         <ValidationObserver ref="observer" v-slot="{ passes }">
          <form ref="form" @submit.prevent="handleSubmit">
            <InputWithValidation
              horizontal
              rules="required"
              type="name"
              v-model="form.title"
              icon="account"
              :responseError="error"
              :label="$getTranslation('form.title.label')"
              :placeholder="$getTranslation('form.title.placeholder')"
            />

            <SelectWithValidation horizontal :options="item.data.surveyProjects" :label="$getTranslation('form.project.label')" v-model="form.survey_instance_slug">
              <option value>{{ $getTranslation('form.project.placeholder') }}</option>
              <option v-for="(survey, index) in item.data.surveyProjects" :key="index" :value="survey.slug">
                  {{ survey.title }}
                </option>
            </SelectWithValidation>

            <b-field label="Created By" horizontal>
              <b-select :placeholder="$getTranslation('form.created_by.placeholder')" v-model="form.created_by">
                <option v-for="(user, index) in item.data.users" :key="index" :value="user.slug">
                  {{ $getTranslation('form.created_by.label') }}
                </option>
              </b-select>
            </b-field>

            <b-field :label="$getTranslation('form.status.label')" horizontal>
              <b-select :placeholder="$getTranslation('form.status.placeholder')" v-model="form.status" required>
                <option v-for="(status, index) in item.data.statuses" :key="index" :value="status">
                  {{ status }}
                </option>
              </b-select>
            </b-field>

            <b-field horizontal :label="$getTranslation('form.description.label')">
              <b-input v-model="form.description" type="textarea">{{ form.description }}</b-input>
            </b-field>
            <hr />
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit" type="is-primary"  @click="passes(submit)">{{ $getTranslation('form.submit') }}</b-button>
                </div>
                <div class="control">
                  <b-button type="is-primary is-outlined" @click="reset">{{ $getTranslation('form.reset') }}</b-button>
                </div>
              </b-field>
            </b-field>
          </form>
        </ValidationObserver>
      </card-component>
    </section>
  </div>
</template>

<script>
import { ApiReporting } from '@/api-routes.js'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'

export default {
  name: 'app-create',
  props: ['slug'],
  mixins: [FormTemplateMixin],
  components: {
    //
  },
  data () {
    return {
      title: this.$getTranslation('form.general.new') + ' ' +  this.$getTranslation('general.views.report'),
      // title: 'New Report',
      endpoint: ApiReporting,
      surveyProjects: null,
      users: null,
      statuses: null,
      // formSession are saved old valus needed for reset
      formSession: {
        title: null,
        description: null,
        // slug is only readable, cannot be changed
        slug: null,
        status: null,
        created_by: null,
        survey_instance_slug: null,
      }
    }
  },
  computed: {

    form: {
      get: function() {
        let form = {}

        form.title = null
        form.description = null
        form.survey_instance_slug = null
        form.status = 'review'
        form.created_by = null
        if (typeof this.slug === "undefined" || this.slug === null) {
          this.formSession = form
          return form
        }

        this.setFormValues (form)

        return form;
      },
      // setter needed to reset the form
      set: function(form) {
        this.setFormValues (form)
      }
    }
  },

  methods: {
    reset () {
      this.form = this.formSession
    },

    setFormValues (form) {
      if(this.item !== null) {
        let item = this.item.data
        form.title = item.reporting.title
        form.survey_instance_slug = item.reporting.survey_instance_slug
        form.created_by = item.reporting.created_by
        form.status = item.reporting.status
        form.description = item.reporting.description
        this.formSession = form
        // return form;
      }
    }
  },

  mounted () {
    let payload = {}
    // console.log('form created: ', this.slug)
    // this.title = this.$route.meta.routeName
    // console.log('slug? ', this.slug, this.formMethod, 'typeof this.slug or null - not working!!')
    if(typeof(this.slug) !== "undefined" && this.slug.length) {
      this.title = this.$getTranslation('form.general.edit') + ' ' +  this.$getTranslation('general.views.report')
      payload = {
        endpoint: this.endpoint + '/details/' + this.slug,
      }
      this.formMethod = 'put'
      this.fetchItem(payload)
    } else {
      payload = {
        endpoint: this.endpoint + '/create',
      }
      this.fetchItem(payload)
    }

    console.log('form method? ', this.formMethod, this.endpoint)
  }
}
</script>
